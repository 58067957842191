header {
    position: relative;
    width: 100%;
    min-height: auto;
    @include background-cover;
    background-position: center;
    background-image: url('../img/header.jpg');
    text-align: center;
    color: white;
    .header-content {
        position: relative;
        text-align: center;
        padding: 100px 15px 100px;
        width: 100%;
        .header-content-inner {
            h1 {
                font-weight: 700;
                text-transform: uppercase;
                margin-top: 0;
                margin-bottom: 0;
            }
            hr {
                margin: 30px auto;
            }
            p {
                font-weight: 700;
                color: rgba(white, .7);
                font-size: 16px;
                margin-bottom: 50px;
            }
        }
    }
    @media (min-width: 768px) {
        min-height: 100%;
        .header-content {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            padding: 0 50px;
            .header-content-inner {
                max-width: 1000px;
                margin-left: auto;
                margin-right: auto;
                p {
                    font-size: 18px;
                    max-width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}
.banner-headline {
    font-size:5rem;
    font-style: italic;
    font-weight: 200;
    margin: 0;
    text-align: right;
}
.banner-tagline {font-size:1.5rem;}
@media only screen and (min-width: 560px) {
    .banner-headline {font-size:8rem;}
    .banner-tagline {font-size:1.75rem;}
}
@media only screen and (min-width: 768px) {
    .banner-headline {font-size:10rem;}
    .banner-tagline {font-size:2.25rem;}
}
@media only screen and (min-width: 1170px) {
    .banner-headline {font-size:15rem;}
    .banner-tagline {font-size:3rem;}
}
.banner-your {
    // display: block;
    // width: 50%;
    text-align:right;
}
.banner-words-wrapper {
    // display:block;
    // position:relative;
    // margin-right: 25%;
    // width: 50%;
    text-align:left;
}
.rotating-item {
    font-weight: 900;
    // margin-right: 50px;
    display:none;
    position:absolute;
    // white-space:nowrap;
    left:0;
    // bottom: -10;
}
