html,
body {
    height: 100%;
    width: 100%;
}

body {
    @include serif-font;
}

hr {max-width: 50px;}

a {
    @include transition-all;
    color: $color-1;
    &:hover,
    &:focus {
        color: darken($color-1, 10%);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include sans-serif-font;
}

p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

[class^="bg-color-"]{color: white;}
.bg-color-1 {background-color: $color-1;}  //PURPLE
.bg-color-2 {background-color: $color-2;}  //YELLOW
.bg-color-3 {background-color: $color-3;}  //GREEN
.bg-color-4 {background-color: $color-4;}  //PEACH
.bg-color-black {background-color: $color-black;}
.bg-color-1 hr {border-color: lighten($color-1, 10%);}
.bg-color-2 hr {border-color: lighten($color-2, 10%);}
.bg-color-3 hr {border-color: lighten($color-3, 10%);}
.bg-color-4 hr {border-color: lighten($color-4, 10%);}
.bg-color-black hr {border-color: lighten($color-black, 10%);}

.bg-dark {
    background-color: $color-black;
    color: white;
}

.text-faded {
    color: rgba(white, .7);
}

section, aside, footer {
    padding: 100px 0;
}

.no-padding {
    padding: 0;
}

div[class^="spacer-"]{display: block;}
.spacer-1em {height: 1em;}
.spacer-2em {height: 2em;}
.spacer-3em {height: 3em;}
.spacer-4em {height: 4em;}
.spacer-5em {height: 5em;}
.spacer-10em {height: 10em;}

// Bootstrap Overrides
.text-primary {
    color: $color-1;
}

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

// Button Styles
.btn-default {
    @include button-variant($color-black, white, white);
}

.btn-primary {
    @include button-variant(white, darken($color-1, 10%), $color-1);
}

.btn {
    @include sans-serif-font;
    border: none;
    border-radius: 300px;
    font-weight: 700;
    text-transform: uppercase;
}

.btn-xl {
    padding: 15px 30px;
}

// Contact
#contact .fa {
    color: $color-black;
    font-size: 4em;
}

// Extras
// -- Highlight Color Customization
::-moz-selection {
    color: white;
    text-shadow: none;
    background: $color-black;
}

::selection {
    color: white;
    text-shadow: none;
    background: $color-black;
}

img::selection {
    color: white;
    background: transparent;
}

img::-moz-selection {
    color: white;
    background: transparent;
}
body {
    -webkit-tap-highlight-color: $color-black;
}
