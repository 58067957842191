.section-heading {
    margin-top: 0;
}
#services h3 {margin-top: 0;}
// .service-box {
//     // max-width: 400px;
//     margin: 50px auto 0;
//     @media (min-width: 992px) {
//         margin: 20px auto 0;
//     }
//     p {
//         margin-bottom: 20px;
//     }
// }
img.service-thumb {
    width: 100%;
    height: auto;
}
