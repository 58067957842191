@charset "utf-8";

// Example Defaults
// $base-font-family: Helvetica, Arial, sans-serif;
// $base-font-size:   16px;
// $small-font-size:  $base-font-size * 0.875;
// $base-line-height: 1.5;

// $spacing-unit:     30px;

// $text-color:       #111;
// $background-color: #fdfdfd;
// $brand-color:      #2a7ae2;

// $grey-color:       #828282;
// $grey-color-light: lighten($grey-color, 40%);
// $grey-color-dark:  darken($grey-color, 25%);

// // Width of the content area
// $content-width:    800px;

// $on-palm:          600px;
// $on-laptop:        800px;

// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }

// variables.less
// $theme-primary: #ef767a;
// $theme-secondary: #64a6bd;
// $theme-dark: #222;

$color-black: hsla(0%, 6%, 28%, 1); //PURPLE TAUPE (DARK for text, etc...)
$color-1: hsla(320%, 31%, 64%, 1); //OPERA MAUVE (PURPLE)
$color-2: hsla(45%, 100%, 84%, 1); //NAVAJO WHITE (YELLOW)
$color-3: hsla(122%, 33%, 84%, 1); //TEA GREEN
$color-4: hsla(26%, 85%, 80%, 1); //PEACH

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "mixins",
        "base",
        "nav",
        "banner",
        "about",
        "services",
        "events",
        "contact",
        "footer"
;
