footer p {
	@include sans-serif-font;
	font-size: 1.25rem;
	color: lighten($color-2, 10%);
}

.modal .modal-dialog .modal-content {
	background-color: $color-4;
	padding: 0 20px 20px;
	color: white;
	height: auto;
	margin-top: 20vh;
	border-radius: 0;
}
.modal-close, .modal-unmute {
	position: absolute;
	color: $color-black;
	z-index: 9999;
}
.modal-close:hover, .modal-unmute:hover {
	color: $color-1;
	text-decoration: none;
	cursor: pointer;
}
.modal-close {
	padding: 7px;
	text-shadow: 0 0 3px #666;
	background-color: rgba(0,0,0,0);
	opacity: 1;
	font-weight: 100;
	top: -42px;
	right: 10px;
	font-size: 3em;
}
.modal-unmute {
	cursor: pointer;
	background: transparent;
	border: 1px solid #f58000;
	border-radius: 3px;
	background-color: rgba(255,255,255,1);
	padding: 1px 5px;
	font-weight: 700;
	top: -35px;
	right: 60px;
	font-size: 1.2em;
	max-width: 105px;
}
@media only screen and (min-width: 768px) {
	.modal-close {
		top: 0px;
		right: -40px;
	}
	.modal-unmute {
		top: 10px;
	 	left: 10px;
		font-size: 1em;
		max-width: 90px;
	}
}
/*====VIDEO MODAL====*/
.video-modal .modal-dialog {
	padding: 0;
}
.video-modal .modal-dialog .modal-content {
	background-color: #fff;
	font-size: 0;
	padding: 0;
	height: auto;
	margin-top: 25vh;
	position: relative;
	-webkit-box-shadow: 0px 10px 15px 0px rgba(31,23,3,0.15);
	-moz-box-shadow: 0px 10px 15px 0px rgba(31,23,3,0.15);
	box-shadow: 0px 10px 15px 0px rgba(31,23,3,0.15);
}
.vid-wrap {
	width: 100%;
	display: inline-block;
	border: 1px solid #ddd;
	position: relative;
}
.movie-push {
	margin-top: 56.25%;
}
.movie-container {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: 0;
}
.vid-wrap, .movie-container, .movie-player, .modal-content, .modal-dialog{
	border-radius: 4px;
}
.modal-backdrop.fade.in {
   background-color: #fff;
   opacity:0.8 !important;
}
.modal-backdrop.fade {
	opacity: 0;
}/*====VIDEO MODAL====*/
