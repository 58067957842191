.name-box {
    background-color: $color-1;
    padding: 0 15px;
    display: inline-block;
}
#about h3 {
    font-weight: 900;
    text-align: center;
    padding: 0;
    margin: 0;
    color: lighten($color-1, 10%);
}
.desc-box {
    border: 1px solid lighten($color-1, 10%);
    margin-top: -15px;
    padding: 30px 25px 0;
}
img.text-wrap {
    width: 20%;
    height: auto;
    margin: 5px;
    float: right;
    // clear: right;
}
img.text-wrap-left {
    width: 20%;
    height: auto;
    margin: 20px 20px 5px 0;
    clear: right;
    float: left;
}
