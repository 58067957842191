.navbar-default {
    background-color: white;
    border-color: rgba($color-black, .5);
    @include sans-serif-font;
    @include transition-all;
    .navbar-header .navbar-brand {
        display: block;
        height: 80px;
        width: 80px;
        margin: 10px;
        background: url('../img/ib-icon-purp.svg') no-repeat center center;
        background-size: contain;
        &:hover,
        &:focus {
            color: darken($color-1, 10%);
        }
    }
    .navbar-header .navbar-toggle {
        margin-top: 30px;
        padding: 10px;
        border: 1px solid $color-1;
        background-color: rgba($color-1, .15);
        .icon-bar{background-color: $color-1;}
    }
    .nav {
        > li {
            > a,
            > a:focus {
                text-transform: uppercase;
                font-weight: 700;
                font-size: 13px;
                color: $color-black;
                &:hover {
                    color: $color-1;
                }
            }
            &.active {
                > a,
                > a:focus {
                    color: $color-1 !important;
                    background-color: transparent;
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }
    @media (min-width: 768px) {
        background-color: rgba(white, .8);
        border-color: rgba(white, .9);
        .navbar-header .navbar-brand {
            color: rgba(white, .7);
            height: 100px;
            width: 300px;
            margin: 0;
            background: url('../img/ib-logo-purp.svg') no-repeat center center;
            background-size: contain;
            &:hover,
            &:focus {
                color: white;
            }
        }
        .nav > li > a,
        .nav > li > a:focus {
            margin-top: 22px;
            font-size: 16px;
            color: darken($color-1, 10%);
            &:hover {
                color: $color-1;
            }
        }
        &.affix {
            background-color: white;
            border-color: rgba($color-black, .5);
            .navbar-header .navbar-brand {
                color: $color-1;
                font-size: 14px;
                &:hover,
                &:focus {
                    color: darken($color-1, 10%);
                }
            }
            .nav > li > a,
            .nav > li > a:focus {
                color: $color-black;
                &:hover {
                    color: $color-1;
                }
            }
        }
    }
}
